import ConfiguratorDesign from "views/ConfiguratorDesign"
import ConfiguratorLayout from "components/Configurator/Layout"
import { PageProps } from "gatsby"
import React from "react"
import SEO from "components/seo"
import scrollTo from "gatsby-plugin-smoothscroll"
import { sessionStore } from "utils/StorageUtils"

type CurrentPageProps = PageProps & {
  ProductLine: any
  ProductSubLine: any
}

const ConfiguratorDesignPage: React.FC<CurrentPageProps> = props => {
  React.useEffect(() => {
    const partnerId = sessionStore?.getItem("partnerId")
    if (partnerId) {
      setTimeout(() => scrollTo("#gatsby-focus-wrapper"), 0)
    }
  }, [])

  return (
    // @ts-ignore
    <ConfiguratorLayout
      activePageId="design"
      productLine={props.ProductLine}
      productSubLine={props.ProductSubLine}
    >
      <SEO title="Vizualizator" />
      <ConfiguratorDesign />
    </ConfiguratorLayout>
  )
}

export default ConfiguratorDesignPage
